<template>
  <div class="billing-main">
    <div class="billing-main__head">
      <div class="billing-main__title">
        <page-title>
          {{ $t('title') }}
        </page-title>
        <!--          v-if="!(isProviderRusonyx && notIncludePostpaydServices && payersNotPhysical)"-->
        <base-button
          v-if="!(isProviderRusonyx && notIncludePostpaydServices && payersNotPhysical)"
          class="billing-main__add"
          @click="addPayment"
        >
          {{ $t('add') }}
        </base-button>
      </div>
      <!--      <tabs :list="accessNav" class="medium-title">-->
      <tabs :list="filtredNav" class="medium-title">
        <template v-slot:item="{ item }">
          <router-link active-class="active" :to="item.to">
            {{ item.title }}
          </router-link>
        </template>
      </tabs>
    </div>
    <div class="billing-main__body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import addPayment from '@/mixins/billing/addPayment';
import { postpaid, filterObject, findAllByKeyIncluded } from '@/utils/postpaid';

export default {
  name: 'Billing',
  components: {
    tabs,
  },
  mixins: [addPayment],
  data() {
    return {
      nav: [
        {
          title: this.$t('nav.deposit'),
          to: { name: 'BillingDeposit' },
          access: ['finance.payment'],
        },
        {
          title: this.$t('nav.history'),
          access: ['finance.expense'],
          to: { name: 'BillingHistory' },
        },
        {
          title: this.$t('nav.docs'),
          access: ['finance.customdocs'],
          to: { name: 'BillingDocs' },
        },
        // {
        //   title: this.$t('nav.orders'),
        //   to: { name: 'BillingOrders' },
        // },
        {
          title: this.$t('nav.auto'),
          access: ['finance.payment.recurring.settings'],
          to: { name: 'BillingAutoPayment' },
        },

        {
          title: this.$t('nav.promised'),
          access: ['finance.promisepayment.add'],
          to: { name: 'BillingPromisePayment' },
        },
        {
          title: this.$t('nav.payers'),
          access: ['customer.profile'],
          to: { name: 'BillingPayers' },
        },
      ],
      postpaid: postpaid,
    };
  },
  computed: {
    access() {
      return this.$store.state.moduleProfile.access;
    },
    accessNav() {
      // console.log();
      return this.nav.filter(i => {
        // console.log(i.access[0]);
        // console.log(this.access);
        // console.log(this.access[i.access[0]]);
        // console.log(i.access.flat().toString());
        // return !i.access || (i.access && this[i.access.name] === i.access.value);
        return !i.access || this.access[i.access[0]];
      });
    },
    filtredNav() {
      let nav = [];
      const filtred =
        this.payersNotPhysical && this.notIncludePostpaydServices && this.isProviderRusonyx;
      if (filtred) {
        return (nav = this.nav.filter(x => x.to.name !== 'BillingDeposit'));
      } else {
        return (nav = this.nav);
      }
      // return nav;
    },
    notIncludePostpaydServices() {
      const re = filterObject(this.$store.getters['moduleStart/services'], value => value > 0);
      //   // console.log(re);
      //   // console.log(findAllByKeyIncluded(re, this.postpaid).length === 0);
      return findAllByKeyIncluded(re, this.postpaid).length === 0;
      //   // return this.$store.getters['moduleStart/services'];
    },
    payersNotPhysical() {
      return (
        this.$store.getters['moduleBilling/modulePayers/payers'] &&
        this.$store.getters['moduleBilling/modulePayers/payers'] !== '1'
      );
    },
    isNotReseller() {
      return !this.$store.getters['moduleStart/reseller'] === true;
    },
    isProviderRusonyx() {
      return (
        this.$store.getters['moduleProviders/provider'] &&
        this.$store.getters['moduleProviders/provider'] === '3'
      );
    },
  },
  mounted() {
    // console.log('dboard', this.$store.state.moduleProfile.access);
    this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.info' });
    this.$store.dispatch('moduleStart/fetchDasboardInfo', { func: 'dashboard.services' });
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Биллинг",
    "nav": {
      "deposit": "Пополнение счета",
      "history": "История платежей",
      "docs": "Документы",
      "auto": "Автоплатеж",
      "orders": "Мои услуги",
      "promised": "Обещанный платеж",
      "payers": "Плательщики"
    },
    "add": "Пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-main {
  &__title {
    margin-bottom: 1.5rem;
    +breakpoint(sm-and-up) {
      flexy(space-between, center);
    }
  }
  &__add {
    margin-top: 1rem;

    +breakpoint(sm-and-up) {
      margin-top: 0;
    }
  }
  &__body {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
